import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageFrontpage">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper">
              <div className="home-slider">
                <div className="areaInner">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider_edit_disable{width:100%;min-height:20px;background:#999999;padding:10px;text-align:center;color:#fff}\n                        .flexslider_edit_disable.error{color:#cf0000}\n                        a:focus{outline:none!important;}\n                     "
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider-container4160 {margin:0 auto;position:relative;\t}.flexslider-container4160 img {max-height:450px;}.flexslider-container4160 li {margin-right:0px} .flexslider-container4160 {padding:0px;padding-right:0px;} .flexslider-container4160 img {max-width:100%;}\n                        .flexslider-container4160 {margin:0 auto 0px auto}\n                        .flexslider-container4160 h1 {margin:0;font-size:35px;font-weight:normal;text-align:left;}\n                     "
                    }}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html:
                        ' $(window).on(\'load\', function() {$(".flexslider-container4160").flexslider({animation:"slide",direction:"horizontal",reverse:false,animationLoop:true,startAt:0,slideshow :true,slideshowSpeed :7000,animationSpeed :600,initDelay :0,randomize :false,pauseOnAction :false,pauseOnHover :true,smoothHeight :false,useCSS :true,touch :true,video :true,controlNav :true,directionNav :false,keyboard :true,multipleKeyboard :true,mousewheel :false,pausePlay :false,itemMargin:0,minItems:1,maxItems :1,itemWidth: 1680,\nnamespace: "flex-",\nstart: function(){\n$(".flexslider-img-preloader").css("background-image","none");\n$(".flexslider-img-content").css("opacity","1");\n$(".flexslider-img-content").css("visibility","visible");\n}\n});\n});\n'
                    }}
                  />
                  <div className="external-flexslider-container4160 flexslider-img-preloader">
                    <div className="default-flexslider flexslider-container4160 flexslider-img-content">
                      <ul className="slides4160 default-flex-slides slides">
                        <li>
                          <a href="/pt/nossos-produtos/hydro-infusion/">
                            {" "}
                            <img src="/assets/images/Banner-Allergy-Homepage.png" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                ABSORÇÃO RÁPIDA &amp;
                                <br /> SENSAÇÃO DE ÁGUA <br />
                                REFRESCANTE
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                COM PIZ BUIN HYDRO INFUSION GEL-CREME
                                <sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/pt/nossos-produtos/hydro-infusion/"
                              >
                                Sabia Mais
                              </a>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <a href="/pt/nossos-produtos/active-protect/">
                            {" "}
                            <img src="/assets/images/2019-banner-travel-size-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                EXTRA RESISTENTE
                                <br /> À ÁGUA <br />
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                COM PIZ BUIN ACTIVE &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/pt/nossos-produtos/active-protect/"
                              >
                                Sabia Mais
                              </a>
                            </div>
                          </div>
                        </li> */}
                        {/* <li>
                          <a href="/pt/nossos-produtos/instantglow/">
                            {" "}
                            <img src="/assets/images/2019-instant-glow-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                PROTEGIDA &amp; DESLUMBRANTE - DESDE O 1º DIA E
                                DURANTE TODO O VERÃO
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN INSTANT GLOW<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/pt/nossos-produtos/instantglow/"
                              >
                                Saiba Mais
                              </a>
                            </div>
                          </div>
                        </li> */}
                        <li>
                          <a href="/pt/nossos-produtos/tan_protect/">
                            {" "}
                            <img src="/assets/images/slider-2020-tan-protect.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                Melhora o bronzeado natural
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                href="/pt/nossos-produtos/tan_protect/"
                              >
                                Saiba Mais
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/pt/nossos-produtos/allergy/">
                            {" "}
                            <img src="/assets/images/Banner-Allergy-Homepage.png" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                PROTEÇÃO DA PELE SENSÍVEL AO SOL
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN<sup>®</sup> ALLERGY
                              </p>
                              <a
                                className="btn"
                                href="/pt/nossos-produtos/allergy/"
                              >
                                Saiba Mais
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      clear: "both"
                    }}
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent clearfix">
                <div className="section">
                  <figure>
                    <a href="/pt/nossos-produtos/todos_os_Produtos/">
                      <img src="/assets/images/Products taco.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/pt/nossos-produtos/todos_os_Produtos/">
                        PROTEÇÃO PARA TODAS AS NECESSIDADES
                      </a>
                    </h3>
                    <h4>
                      <a href="/pt/nossos-produtos/todos_os_Produtos/">
                        Conheça a gama completa de <br />
                        PIZ BUIN<sup>®</sup>
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/pt/sobre_o_sol/">
                      <img src="/assets/images/CallToAction_640x370px_KnowTheSun.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/pt/sobre_o_sol/">APRENDA SOBRE O SOL</a>
                    </h3>
                    <h4>
                      <a href="/pt/sobre_o_sol/">
                        O nosso guia para aproveitar o sol com segurança
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/pt/a_nossa_historia/">
                      <img src="/assets/images/mobile_landing_OurHeritage_x2.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/pt/a_nossa_historia/">A NOSSA HISTÓRIA</a>
                    </h3>
                    <h4>
                      <a href="/pt/a_nossa_historia/">
                        Especialista nos cuidados solares da pele há mais de 70
                        anos
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.min.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
      </div>
    );
  }
}

export default Page;
