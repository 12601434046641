/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="pt" />
          <body className="desktop" id />
          <meta charSet="UTF-8" />
          <meta name="description" content />
          <meta name="Keywords" content />
          <meta
            content="width=device-width,initial-scale=1, minimum-scale=0.5, maximum-scale=1.0"
            name="viewport"
          />
          <title>Piz Buin</title>
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:400,800,700,600,300&subset=latin"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="//fast.fonts.net/cssapi/a23edeb8-5888-4291-b2f7-2b67a0b2708d.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/styles.css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/vendor/iealert.css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/vendor/jquery.fancybox.min.css"
            rel="stylesheet"
          />
          <link rel="dns-prefetch" href="https://www.googletagmanager.com"/>
          <link rel="dns-prefetch" href="https://fast.fonts.net"/>
          <link rel="dns-prefetch" href="https://cdn.cookielaw.org" />
          <link rel="dns-prefetch" href="https://geolocation.onetrust.com"/>
          


           <link rel="preload" href="https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2" as="font" type="font/woff2" crossorigin />
           <link rel="preload" href="https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2" as="font" type="font/woff2" crossorigin />
           <link rel="preload" href="https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2" as="font" type="font/woff2" crossorigin />
           <link rel="preload" href="https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2" as="font" type="font/woff2" crossorigin />
           <link rel="preload" href="https://fast.fonts.net/dv/14/9bcb625d-81de-48c2-ba0c-664558d197e3.woff2?d44f19a684109620e484167aa090e818d1a9d662ab5b8cff312b899ca3217bec7775dbbee7f595e028c1169c910bb627e336e6dca2e3d1afdaf4953e4fb6adff8a7c6ec482daf584458d9858735e7c62805eea9ad52278ed6df6356c7434ada708fab3deaf3fafddd2429e641dbf13ad99400be9d07a1609a8b9f024c1d3ddc9371f45fed13099c994d69a17d275691e9024bbc48ae50feb54e01ee601a8602c7b18ef141ac1d799603365cf6df43ac5bf313999ea321b9b672be25a1a2785e496ce9bfc90f8ad4866ef6b2616132bee52259dca53018a2edc2e97f9a5a0d9cc8ecbe3bd66a09f430113049240&projectId=a23edeb8-5888-4291-b2f7-2b67a0b2708d" as="font" type="font/woff2" crossorigin />
           <link rel="preload" href="https://fast.fonts.net/dv2/14/4fb9056b-4ac3-488b-9bac-75741b0ff0bd.woff2?d44f19a684109620e484167aa090e818d1a9d662ab5b8cff312b899ca3217bec7775dbbee7f595e028c1169c910bb627e336e6dca2e3d1afdaf4953e4fb6adff8a7c6ec482daf584458d9858735e7c62805eea9ad52278ed6df6356c7434ada708fab3deaf3fafddd2429e641dbf13ad99400be9d07a1609a8b9f024c1d3ddc9371f45fed13099c994d69a17d275691e9024bbc48ae50feb54e01ee601a8602c7b18ef141ac1d799603365cf6df43ac5bf313999ea321b9b672be25a1a2785e496ce9bfc90f8ad4866ef6b2616132bee52259dca53018a2edc2e97f9a5a0d9cc8ecbe3bd66a09f430113049240&projectId=a23edeb8-5888-4291-b2f7-2b67a0b2708d" as="font" type="font/woff2" crossorigin />

          
          <script
            type="text/javascript"
            innerHTML="
var lang = 'pt';
"
          />
          <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
          <title>PIZ BUIN - Portugal</title>
          <meta name="description" content="Portugal" />
         
          <link
            rel="shortcut icon"
            href="/assetsassets/icons/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="icon"
            href="/assetsassets/icons/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/vendor/ccm.base.css"
          />
          <script type="text/javascript" src="/assets/js/vendor/jquery.js" />  

<script type="text/javascript" src="/assets/js/vendor/jquery-migrate-3.0.0.min.js" />
          <script type="text/javascript" src="/assets/js/vendor/ccm.base.js" />
          <script type="text/javascript">
function OptanonWrapper() { }
</script>
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/vendor/flex-slider.css"
          />
          <script
            type="text/javascript"
            src="/assets/js/vendor/jquery.flexslider-min.js"
          />
          
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
